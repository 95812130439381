<template>
  <div>
    <div class="flex-1">
      <p>
        SN:
        <span class="font-semibold">{{device.playersn}}</span>
        ID:
        <span class="font-semibold">{{device.playerid}}</span>
        AD.ID:
        <span class="font-semibold">{{device.settingsjson.PLAYER_ID}}</span>
        STATE:
        <span class="font-semibold">{{device.configstate}}</span>
        REMOTE_STATE:
        <span class="font-semibold">{{device.device_config_applied?'APPLIED':'NOT_APPLIED'}}</span>
        CFG:
        <span class="font-semibold">{{new Date(device.configversion).toLocaleTimeString([], { year: 'numeric', month: '2-digit', day: '2-digit' }).split(", ").join("-")}}</span>
        LAST_CONTACT:
        <span class="font-semibold">{{new Date(device.last_contact_at).toLocaleTimeString([], { year: 'numeric', month: '2-digit', day: '2-digit' }).split(", ").join("-")}}</span>
      </p>
    </div>
    <div class="flex">
      <div class="flex items-center text-blue-600 px-2 rounded-md hover:bg-gray-200 font-medium">
        <button
          v-on:click.prevent="loadViewDevice(device)"
          class="inline-flex items-center"
        >
          View
          <span class="px-1">
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              class="eye w-4 h-4"
            >
              <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
              <path
                fill-rule="evenodd"
                d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>
      </div>
      <div class="flex items-center text-blue-600 px-2 rounded-md hover:bg-gray-200 font-medium">
        <button
          v-on:click.prevent="loadEditDevice(device)"
          class="inline-flex items-center"
        >
          Edit
          <span class="px-1">
            <svg
              viewBox="0 0 20 20"
              fill="currentColor"
              class="cog w-4 h-4"
            >
              <path
                fill-rule="evenodd"
                d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </button>
      </div>
      <div class="flex items-center text-gray-900 px-2 rounded-md hover:bg-gray-200 font-medium">
        <button
          v-on:click.prevent="decommissionDevice(device)"
          class="inline-flex items-center"
        >
          Decommission
          <span class="px-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
                d="M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636"
              />
            </svg>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeviceConfigured",
  props: ["device"],
  data() {
    return {};
  },
  methods: {
    loadViewDevice: function (device) {
      this.$emit("loadviewdevice", device);
    },
    loadEditDevice: function (device) {
      this.$emit("loadeditdevice", device);
    },
    decommissionDevice: function (device) {
      this.$emit("decommissiondevice", device);
    },
  },
};
</script>
