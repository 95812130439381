<template>
  <div class="h-full w-full">
    <div
      v-if="hasError"
      class="min-h-full min-w-full flex bg-gray-500"
    >
      <div class="flex mx-auto my-auto text-red-400 flex-row">
        <p class="font-normal text-2xl">
          <span class="font-extrabold text-6xl">ERROR</span>
          Please log out and back in
        </p>
      </div>
    </div>
    <div
      v-else
      class="h-full flex flex-col px-0 pt-2 pr-2 pb-4"
    >
      <div
        v-show="DEVICES_UNCONFIGURED.length > 0"
        class="bg-blue-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex"
      >
        <div class="flex my-auto ml-1">
          UNCONFIGURED / {{ DEVICES_UNCONFIGURED.length }}
        </div>
        <div>
          <button
            class="flex font-normal px-2 mr-1 border border-white my-2 rounded-sm"
            v-on:click.prevent="GET_DEVICE_CONFIGS()"
          >
            REFRESH
          </button>
        </div>
      </div>
      <div
        v-show="DEVICES_UNCONFIGURED.length > 0"
        class="flex-col px-0 py-1 leading-7 flex overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none"
      >
        <DeviceUnconfigured
          v-for="device in DEVICES_UNCONFIGURED"
          v-bind:key="device.id"
          v-bind:device="device"
          @loadviewdevice="loadViewDevice(device)"
          @loadeditdevice="loadEditDevice(device)"
          @commitdeviceconfig="commitDeviceConfig(device)"
          @decommissiondevice="startDecommissionDevice(device)"
          @replacedevice="startReplaceDevice(device)"
          class="text-xs px-1 leading-5 border-b border-dashed hover:bg-gray-400 flex flex-row items-center py-2 font-mono"
        ></DeviceUnconfigured>
      </div>
      <div
        v-show="DEVICES_CONFIGURED.length > 0"
        class="bg-green-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex"
      >
        <div class="flex my-auto ml-1">
          CONFIGURED / {{ DEVICES_CONFIGURED.length }}
        </div>
        <div>
          <button
            class="flex font-normal px-2 mr-1 border border-white my-2 rounded-sm"
            v-on:click.prevent="GET_DEVICE_CONFIGS()"
          >
            REFRESH
          </button>
        </div>
      </div>
      <div
        v-show="DEVICES_CONFIGURED.length > 0"
        class="flex-col px-0 py-1 leading-7 flex-1 overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none"
      >
        <DeviceConfigured
          v-for="device in DEVICES_CONFIGURED"
          v-bind:key="device.id"
          v-bind:device="device"
          @loadviewdevice="loadViewDevice(device)"
          @loadeditdevice="loadEditDevice(device)"
          @decommissiondevice="startDecommissionDevice(device)"
          class="text-xs px-1 leading-5 border-b border-dashed hover:bg-gray-400 flex flex-row items-center py-2 font-mono"
        ></DeviceConfigured>
      </div>
      <div
        v-show="DEVICES_DECOMMISSIONED.length > 0"
        class="bg-red-400 font-normal text-white px-1 mt-2 rounded-sm rounded-b-none mb-1 justify-between flex"
      >
        <div class="flex my-auto ml-1">
          DECOMMISSIONED / {{ DEVICES_DECOMMISSIONED.length }}
        </div>
        <div>
          <button
            class="flex font-normal px-2 mr-1 border border-white my-2 rounded-sm"
            v-on:click.prevent="GET_DEVICE_CONFIGS()"
          >
            REFRESH
          </button>
        </div>
      </div>
      <div
        v-show="DEVICES_DECOMMISSIONED.length > 0"
        class="flex-col px-0 py-1 leading-7 flex overflow-y-scroll border border-gray-50 bg-gray-100 rounded-sm rounded-t-none"
      >
        <DeviceDecommissioned
          v-for="device in DEVICES_DECOMMISSIONED"
          v-bind:key="device.id"
          v-bind:device="device"
          @loadviewdevice="loadViewDevice(device)"
          @loadeditdevice="loadEditDevice(device)"
          @deletedevice="startDeleteDevice(device)"
          class="text-xs px-1 leading-5 border-b border-dashed hover:bg-gray-400 flex flex-row items-center py-2 font-mono"
        ></DeviceDecommissioned>
      </div>
    </div>
    <div
      v-if="showDeviceConfigViewPanel"
      class="fixed inset-0 overflow-y-hidden"
    >
      <div class="absolute inset-0 overflow-hidden">
        <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <div class="w-screen max-w-4xl">
            <div class="h-full flex flex-col space-y-1 py-4 bg-gray-50 shadow-xl">
              <header class="px-4">
                <div class="flex items-start justify-between space-x-3">
                  <h2 class="text-lg leading-7 font-medium text-gray-900">
                    PLAYER_SN:{{ JSON.parse(VIEW_CONTENT).playersn }} -
                    PLAYER_ID:{{ JSON.parse(VIEW_CONTENT).playerid }}
                  </h2>
                  <div class="h-7 flex items-center">
                    <button
                      v-on:click.prevent="
                        showDeviceConfigViewPanel = !showDeviceConfigViewPanel
                      "
                      aria-label="Close panel"
                      class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                    >
                      <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </header>
              <div class="relative flex-1 px-2 py-2 max-h-full overflow-y-scroll">
                <div class="inset-0 px-2">
                  <div class="h-full flex-row px-2 py-2 bg-white rounded-sm">
                    <pre><code class="text-xs leading-5 font-mono">{{VIEW_CONTENT}}</code></pre>
                  </div>
                </div>
                <!-- /End replace -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      v-if="showDeviceConfigEditPanel"
      class="fixed inset-0 overflow-hidden"
    >
      <div class="absolute inset-0 overflow-hidden">
        <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
          <div class="w-screen max-w-4xl">
            <div class="h-full flex flex-col space-y-2 py-2 bg-gray-50 shadow-xl overflow-y-scroll">
              <header class="px-4">
                <div class="flex items-start justify-between space-x-3">
                  <h2 class="text-lg leading-7 font-medium text-gray-900">
                    PLAYER_SN:{{ JSON.parse(EDITOR_CONTENT).playersn }} -
                    PLAYER_ID:{{ JSON.parse(EDITOR_CONTENT).playerid }}
                  </h2>
                  <div class="h-7 flex items-center">
                    <button
                      v-on:click.prevent="
                        showDeviceConfigEditPanel = !showDeviceConfigEditPanel
                      "
                      aria-label="Close panel"
                      class="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150"
                    >
                      <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="flex items-center flex-row py-2">
                  <div class="flex text-sm mr-2">Only edit:</div>
                  <div class="flex-1 font-mono font-semibold text-xs">
                    location_* playerid playerpath* settingsjson* updatecycle
                    syncchannels* tempsettings*
                  </div>
                  <div class="flex">
                    <button
                      v-on:click.prevent="UPDATE_DEVICE_CONFIG()"
                      class="rounded-md bg-blue-400 border text-gray-700 px-2 shadow-md font-mono"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </header>
              <div class="h-full relative flex-1 px-2">
                <div class="h-full inset-0 px-2">
                  <div class="h-full w-full flex-row px-2">
                    <MonacoEditor
                      width="100%"
                      height="100%"
                      theme="vs"
                      language="javascript"
                      :options="options"
                      :value="EDITOR_CONTENT"
                      @change="onChange"
                    ></MonacoEditor>
                  </div>
                </div>
                <!-- /End replace -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <modal
      name="replaceDevice"
      height="500"
      width="800"
      class=""
    >
      <div class="flex flex-col text-center border-8 border-red-600 h-full bg-gray-200">
        <div class="px-2 py-2 border-b border-gray-700 text-center bg-blue-300">
          <h3 class="text-xl">Select device that you would like to replace</h3>
        </div>
        <div class="px-2 py-2 border-b border-gray-700 text-center bg-orange-400 font-extrabold">
          <h3 class="text-lg">Make sure this is a decommissioned, shutdown device!</h3>
        </div>
        <div class="my-8 font-semibold">THIS DEVICE:</div>
        <div class="font-semibold font-mono text-gray-800">SN:{{REPLACEMENT_DEVICE.playersn}} - ID:{{REPLACEMENT_DEVICE.playerid}} - STATE:{{REPLACEMENT_DEVICE.configstate}}</div>
        <div class="my-8 font-bold">WILL BE CONFIGURED AS THIS DEVICE:</div>
        <div class="my-4 font-semibold flex-col">
          <select
            v-model="TO_BE_REPLACED_ID"
            class="mx-2 rounded-sm text-base font-mono border border-gray-700"
          >
            <option value>select device</option>
            <option
              v-for="device in DEVICES_DECOMMISSIONED"
              :key="device.playersn"
              :value="device.id"
            >{{
              device.playerid
            }}</option>
          </select>
        </div>
        <div
          class="my-4 font-semibold font-mono text-gray-800"
          v-for="device in DEVICE_TO_REPLACE"
          :key="device.id"
        >
          SN:{{device.playersn}} - ID:{{device.playerid}} - STATE:{{device.configstate}}
        </div>
        <div class="my-4 font-semibold font-mono text-gray-800">
          <button
            v-show="TO_BE_REPLACED_ID!=''"
            class="rounded-sm px-2 shadow-lg border border-black bg-red-200"
            v-on:click.prevent="replaceDevice(TO_BE_REPLACED_ID,REPLACEMENT_DEVICE.playersn)"
          >replace device</button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import DeviceUnconfigured from "./components/DeviceUnconfigured";
import DeviceConfigured from "./components/DeviceConfigured";
import DeviceDecommissioned from "./components/DeviceDecommissioned";
import MonacoEditor from "monaco-editor-vue";
export default {
  name: "Deviceconfigs",
  components: {
    DeviceConfigured,
    DeviceUnconfigured,
    DeviceDecommissioned,
    MonacoEditor,
  },
  beforeMount() {
    this.USER_OBJECT = JSON.parse(localStorage.getItem("USER_OBJECT"));
    this.$store.commit("UPDATE_USER_OBJECT", this.USER_OBJECT);
    this.AUTH_HEADER = JSON.parse(localStorage.getItem("AUTH_HEADER"));
    this.$store.commit("UPDATE_AUTH_HEADER", this.AUTH_HEADER);
    axios.defaults.headers.common["Authorization"] = this.AUTH_HEADER;
  },
  mounted() {
    this.GET_DEVICE_CONFIGS();
  },
  data() {
    return {
      hasError: false,
      USER_OBJECT: {},
      AUTH_HEADER: "",
      DEVICES: [],
      VIEW_CONTENT: "",
      EDITOR_CONTENT: "",
      REPLACEMENT_DEVICE: {},
      TO_BE_REPLACED_ID: "",
      showDeviceConfigViewPanel: false,
      showDeviceConfigEditPanel: false,
      options: {
        scrollBeyondLastLine: false,
      },
    };
  },
  computed: {
    DEVICES_UNCONFIGURED: function () {
      return this.DEVICES.filter(
        (device) => device.configstate == "UNCONFIGURED"
      ).sort((a, b) => (a.playerid > b.playerid ? 1 : -1));
    },
    DEVICES_CONFIGURED: function () {
      return this.DEVICES.filter(
        (device) => device.configstate == "CONFIGURED"
      ).sort((a, b) => (a.playerid > b.playerid ? 1 : -1));
    },
    DEVICES_DECOMMISSIONED: function () {
      return this.DEVICES.filter(
        (device) => device.configstate == "DECOMMISSIONED"
      ).sort((a, b) => (a.playerid > b.playerid ? 1 : -1));
    },
    DEVICE_TO_REPLACE: function () {
      return this.DEVICES.filter(
        (device) => device.playerid == this.TO_BE_REPLACED_ID
      );
    },
  },
  methods: {
    onChange(value) {
      this.EDITOR_CONTENT = value;
    },
    loadViewDevice: function (device) {
      this.VIEW_CONTENT = JSON.stringify(device, null, 4);
      this.showDeviceConfigViewPanel = true;
    },
    loadEditDevice: function (device) {
      this.EDITOR_CONTENT = JSON.stringify(device, null, 2);
      this.showDeviceConfigEditPanel = true;
    },
    startDeleteDevice: function (device) {
      const yesno = confirm(
        "DANGER! Will Robinson!!!\nDo you really want to delete this device?"
      );
      if (yesno) {
        /* request second confirmation */
        const yesnoConfirm = confirm(
          "DANGER! Will Robinson!!!\nAre you really sure to DELETE this device?"
        );
        if (yesnoConfirm) {
          /* DELETE DEVICE REQUEST HERE */
          this.deleteDevice(device);
          alert(`DELETED ${device.id}`);
        } else {
          /* break execution*/ alert("Deleting device aborted!");
        }
      } else {
        /* break execution*/ alert("Deleting device aborted!");
      }
    },
    // startreplaceDevice: function (device) {
    startReplaceDevice: function (device) {
      this.REPLACEMENT_DEVICE = device;
      this.$modal.show("replaceDevice");
    },
    startDecommissionDevice: function (device) {
      const yesno = confirm(
        "DANGER! Will Robinson!!!\nDo you really want to decommission this device?"
      );
      if (yesno) {
        /* request second confirmation */
        const yesnoConfirm = confirm(
          "DANGER! Will Robinson!!!\nAre you really sure to DECOMMISSION this device?"
        );
        if (yesnoConfirm) {
          /* DELETE DEVICE REQUEST HERE */
          this.decommissionDevice(device);
          alert(`DECOMMISSIONED ${device.id}`);
        } else {
          /* break execution*/ alert("Decommsission device aborted!");
        }
      } else {
        /* break execution*/ alert("Decommsission device aborted!");
      }
    },
    // doreplaceDevice: function (newDeviceID, oldDeviceSN) {},
    GET_DEVICE_CONFIGS: function () {
      this.$store.dispatch("GET_DEVICE_CONFIGS", {}).then(
        (response) => {
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          }
          if (error.response.status >= 500 && error.response.status <= 599) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    UPDATE_DEVICE_CONFIG: function () {
      let editDevice = JSON.parse(this.EDITOR_CONTENT);
      editDevice.configstate = "UNCONFIGURED";
      editDevice.configversion = "NOT_SET";
      this.EDITOR_CONTENT = "";
      this.showDeviceConfigEditPanel = false;
      this.$store.dispatch("POST_UPDATE_DEVICE_CONFIG", { editDevice }).then(
        (response) => {
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    commitDeviceConfig: function (device) {
      let { id } = device;
      this.$store.dispatch("POST_COMMIT_DEVICE_CONFIG", { id }).then(
        (response) => {
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    decommissionDevice: function (device) {
      let { id } = device;
      this.$store.dispatch("POST_DECOMMISSION_DEVICE", { id }).then(
        (response) => {
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    deleteDevice: function (device) {
      let { id } = device;
      this.$store.dispatch("POST_DELETE_DEVICE", { id }).then(
        (response) => {
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
    replaceDevice: function (id, sn) {
      this.$store.dispatch("POST_REPLACE_DEVICE", { id, sn }).then(
        (response) => {
          this.$modal.hide("replaceDevice");
          this.DEVICES = response.data;
        },
        (error) => {
          if (error.response.status >= 400 && error.response.status <= 499) {
            this.hasError = true;
            localStorage.removeItem("USER_OBJECT");
            localStorage.removeItem("AUTH_HEADER");
            this.$store.commit("UPDATE_USER_OBJECT", null);
            this.$store.commit("UPDATE_AUTH_HEADER", null);
            this.$router.push("/");
          } else if (
            error.response.status >= 500 &&
            error.response.status <= 599
          ) {
            alert("Internal Server Error :(");
          }
        }
      );
    },
  },
};
</script>
